import * as React from "react"
import { Link } from "gatsby"
import ContentCard from "./contentCard"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { ArrowBackIos } from "@material-ui/icons"
import { StaticImage } from "gatsby-plugin-image"
import { getBlockPage } from "../../api/content"
const Block = ({
  userHasSuscription,
  block,
  subtree,
  sectionId,
  onViewAllClick,
}) => {
  const regex = /[0-9]/
  const [section] = React.useState(
    sectionId == "Entrenamiento" || sectionId == "Home"
      ? "60f97c29ce3f0e0e5c92d2a3"
      : "65301f7b965e9b12688fd049"
  )

  const [cards, setCards] = React.useState([])
  const [selected, setSelected] = React.useState([])
  const [actualPage, setActualPage] = React.useState(3)
  const [isOnLimit, setIsOnLimit] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [showOtherBlock, setShowOtherBlock] = React.useState(true)

  const onViewAllClickSend = () => {
    // Esta función se ejecutará cuando se haga clic en "Ver todo"
    setShowOtherBlock(false)
    onViewAllClick()
  }

  const isItemSelected = id => !!selected.find(el => el === id)

  const handleClick =
    id =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id)
      setSelected(currentSelected =>
        itemSelected
          ? currentSelected.filter(el => el !== id)
          : currentSelected.concat(id)
      )
    }

  const addNewCard = () => {
    if (isOnLimit || block.titulo === "Recomendados para ti" || isLoading) {
      return
    }

    setIsLoading(true)

    getBlockPage(block.id, sectionId, actualPage).then(data => {
      setIsOnLimit(!data.pagination.hasMorePages)

      setActualPage(actualPage + 1)
      setCards(prevCards => [...prevCards, ...data.cards])
      setIsLoading(false) // Restaura el estado después de que la consulta haya terminado
    })
  }

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext)

    return (
      <ArrowBackIos
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      />
    )
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext)

    return (
      <ArrowForwardIosIcon
        disabled={isLastItemVisible || isLoading} // Deshabilitar mientras se carga
        onClick={() => {
          addNewCard()
          scrollNext()
        }}
      />
    )
  }

  function Card({ onClick, selected, title, itemId, tarjeta }) {
    const visibility = React.useContext(VisibilityContext)

    return (
      <div className={block.cards ? "mr-4" : "mr-4"}>
        <ContentCard
          userHasSuscription={userHasSuscription ?? null}
          card={tarjeta}
        ></ContentCard>
      </div>
    )
  }

  React.useEffect(() => {
    setCards(block.cards ? block.cards : block.entrenamientos)
  }, [block.cards, block.entrenamientos])

  return (
    <div className="mb-8">
      <div className="flex w-full justify-between mb-4">
        <h3 className="text-sc-titles">{block.titulo}</h3>
        {cards && cards.length > 0 && !subtree ? (
          <>
            {sectionId !== "Recomendados" ? (
              <Link
                style={{ cursor: "pointer" }}
                to={`/sections/${regex.test(sectionId) ? sectionId : section}/${
                  block._id
                }`}
              >
                Ver todo
              </Link>
            ) : (
              showOtherBlock && (
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() => onViewAllClickSend()} // Oculta el otro bloque al hacer clic
                >
                  Ver todo
                </button>
              )
            )}
          </>
        ) : null}
      </div>

      {cards && cards.length > 0 ? (
        <div>
          <div className="hidden md:block">
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
              {cards.map((tarjeta, key) => {
                return (
                  <Card
                    key={key}
                    itemId={tarjeta._id}
                    onClick={handleClick(tarjeta.id)}
                    selected={isItemSelected(tarjeta.id)}
                    tarjeta={tarjeta}
                  />
                )
              })}
            </ScrollMenu>
          </div>
          <div className="block md:hidden flex overflow-x-scroll w-full  scroll-hidden">
            {cards.map((card, key) => (
              <div className="mr-4" key={key}>
                {" "}
                <ContentCard
                  userHasSuscription={userHasSuscription ?? null}
                  card={card}
                ></ContentCard>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <StaticImage
          alt="SHIFT"
          src="../../images/logo_gray.png"
          className="w-32 h-10 p-2 mr-4 mt-0 "
        ></StaticImage>
      )}
    </div>
  )
}

export default Block
