import React, { useEffect, useState } from "react"
import Block from "./block"
import config, { firebaseInit } from "../../config"
import { getSuggestions, getOneBlock } from "../../api/content"
import FilterButton from "../buttons/FilterButton.js"
import {
  ContactSupportOutlined,
  SettingsBackupRestoreRounded,
} from "@material-ui/icons"
import { navigate } from "gatsby-link"
import SignInModal from "../modals/SignInModal"
import PaymentMethodModal from "../modals/PaymentMethodModal"
import NewPaymentFormModal from "../modals/NewPaymentFormModal"
import SubscriptionMessageModal from "../modals/SubscriptionMessageModal"
import CvvModal from "../forms/payments/cvvModal"
import showPerfilForm from "../modals/PerfilFormModal"
import PerfilForm from "../modals/PerfilFormModal"
import firebase from "firebase"
import DeleteMessageModal from "../modals/DeleteMessageModal"
import { StaticImage } from "gatsby-plugin-image"
import PremiumButton from "../../components/buttons/PremiumButton"

const Section = ({
  section,
  userHasSuscription,
  suscription,
  params,
  onFilterChange,
}) => {
  console.log(section.titulo == "home")
  // let sectionId = "60f97c29ce3f0e0e5c92d2a3"
  const profileUri = config.serverUri
  const [sectionId] = React.useState(section.titulo)
  // console.log(section.titulo)
  const [suggestions, setSuggestions] = React.useState({})
  const [SectionFiltrada, setSectionFiltrada] = React.useState(null)
  const [showSignInForm, setShowSignInForm] = React.useState(false)
  const [showPaymentMethod, setShowPaymentMethod] = React.useState(false)
  const [showNewPayment, setShowNewPayment] = React.useState(false)
  const [showSuccessful, setShowSuccessful] = React.useState(false)
  const [showCvvHelp, setShowCvvHelp] = React.useState(false)
  const [showPerfilForm, setShowPerfilForm] = React.useState(false)
  const [ispwa, setIspwa] = React.useState(null)
  const [userSuscription, setUserSuscription] = React.useState(null)
  const [showDeleteMessage, setShowDeleteMessage] = React.useState(null)
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null)
  const [isApplePay, setIsApplePay] = React.useState(false)
  const [searchBlock, setSearchBlock] = React.useState(null)
  const [userId, setUserId] = useState(null)
  const [showRecommended, setShowRecommended] = React.useState(true)
  const [showOtherBlock, setShowOtherBlock] = React.useState(true) // Agrega un estado para controlar la visibilidad del otro bloque

  const onViewAllClick = () => {
    // Esta función se ejecutará cuando se haga clic en "Ver todo"
    setShowOtherBlock(false) // Oculta el otro bloque
  }

  useEffect(() => {
    const getCurrentUser = async () => {
      const user = firebase.auth().currentUser

      if (user) {
        try {
          const response = await fetch(
            `${config.serverUri}/users?email=${user.email}`
          )
          if (response.ok) {
            const data = await response.json()
            setUserId(data[0].id)
          } else {
            // Manejar el error de la petición
            console.error("Error al obtener el usuario por correo electrónico")
          }
        } catch (error) {
          // Manejar errores de red u otros errores
          console.error("Error en la petición: ", error)
        }
      } else {
        // El usuario no está autenticado, puedes manejarlo según tus necesidades
      }
    }

    getCurrentUser()
  }, [])

  const location = ""

  const handleFilter = nuevoBloque => {
    setSectionFiltrada(nuevoBloque)
    onFilterChange(nuevoBloque.id)
  }

  React.useEffect(() => {
    if (!userId) {
      return
    }
    console.log("section.id ", section.id)
    getSuggestions(userId, section.id).then(data => {
      // console.log(data)
      console.log("section ", section)
      if (section.seccion) {
        // Ocultar la sección de "Recomendados para ti" cuando se cargan sugerencias
        setShowRecommended(false)
      }

      data._id = section.id
      // data.sectionId = section.section_id
      data.titulo = "Recomendados para ti"
      data.isBlock = false
      setSuggestions(data)
    })
    if (location == "") {
      setIspwa(false)
    } else {
      setIspwa(true)
    }
  }, [userId])

  React.useEffect(() => {
    getOneBlock(section.id).then(data => {
      setSearchBlock(data)
    })
  }, [])

  const fakeSuggestionsBlock = {
    titulo: "Recomendaciones para ti",
    createdAt: new Date().toISOString(),
    id: null,
    cards: suggestions,
  }

  return (
    <div>
      <header>
        <div>
          <div className="flex relative" style={{ height: 240, width: "100%" }}>
            {section && section.portrait && section.portrait.url ? (
              <img
                className="absolute"
                src={
                  section.portrait.url.trim().startsWith("http")
                    ? section.portrait.url.trim()
                    : config.serverUri + section.portrait.url.trim()
                }
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={section.name}
              />
            ) : (
              <img
                className="absolute"
                src={
                  section.seccion.portrait.url.trim().startsWith("http")
                    ? section.seccion.portrait.url.trim()
                    : config.serverUri + section.seccion.portrait.url.trim()
                }
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={section.name}
              />
            )}

            <div
              className={`absolute flex flex-col  md:justify-end  md:p-5  items-start h-full ${
                section.titulo === "Home" && userHasSuscription !== "premium"
                  ? "px-5 justify-start"
                  : "p-5 justify-end"
              }`}
            >
              <h1 className="text-white">{section.label}</h1>
              <p className="text-white">{section.description ?? ""}</p>
              <div className=" flex flex-row visible md:invisible w-full absolute  bottom-3 ">
                <StaticImage
                  alt="Disponible en Ios"
                  src="../../images/store_icon_ios.png"
                  className="w-10 h-10 p-4  mr-4 mt-0 "
                ></StaticImage>
                <StaticImage
                  alt="Disponible en Android"
                  src="../../images/store_icon_android.png"
                  className="w-10 h-10 p-4 mr-4 mt-0 "
                ></StaticImage>
                <StaticImage
                  alt="Disponible en AppGallery"
                  src="../../images/store_icon_huawei.png"
                  className=" w-10 h-10 p-4 mr-4 mt-0 "
                ></StaticImage>
              </div>
              <div className=" flex flex-row invisible md:visible w-full">
                <StaticImage
                  alt="Disponible en Ios"
                  src="../../images/disponible_ios.png"
                  className="w-32 h-10 p-2  mr-4 mt-0 "
                ></StaticImage>
                <StaticImage
                  alt="Disponible en Android"
                  src="../../images/disponible_android.png"
                  className="w-32 h-10 p-2 mr-4 mt-0 "
                ></StaticImage>
                <StaticImage
                  alt="Disponible en AppGallery"
                  src="../../images/disponible_appGallery.png"
                  className=" w-32 h-10 p-2 mr-4 mt-0 "
                ></StaticImage>
              </div>
            </div>

            {section.titulo === "Home" && userHasSuscription !== "premium" ? (
              <div
                className="absolute flex justify-center "
                style={{ height: 240, width: "100%" }}
              >
                <div className="flex flex-col justify-items-stretch content-end h-full p-8 mt-2">
                  <p className="text-white text-center text-2xl mb-0">
                    Prueba gratis por 7 días
                  </p>
                  <p className="text-white text-center text-lg mb-0">
                    Disfruta de contenido ilimitado. ¿Qué esperas?
                  </p>

                  <PremiumButton
                    href="#"
                    onClick={() => {
                      userHasSuscription !== "free" &&
                      userHasSuscription !== null
                        ? setShowSignInForm(true)
                        : setShowPaymentMethod(true)
                    }}
                  >
                    Hazte premium
                  </PremiumButton>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </header>
      <main className="p-8 mb-32">
        <div className="filters-section">
          <FilterButton
            section={section}
            onSectionFilterChange={e => handleFilter(e)}
          ></FilterButton>
        </div>

        {userHasSuscription === "premium" && showRecommended ? (
          <Block
            key={fakeSuggestionsBlock._id}
            subtree={suggestions.isBlock}
            block={fakeSuggestionsBlock}
            sectionId={"Recomendados"}
            userHasSuscription={userHasSuscription ?? null}
            onViewAllClick={onViewAllClick}
          />
        ) : null}

        {searchBlock !== null ? (
          <>
            <Block
              subtree={true}
              block={searchBlock}
              sectionId={searchBlock.id}
              userHasSuscription={userHasSuscription ?? null}
            ></Block>
          </>
        ) : null}

        {/* Solo funciona en "index", en sections[name] no se renderiza */}

        {showOtherBlock && SectionFiltrada !== null ? (
          <>
            {SectionFiltrada.blocks.map((bloque, key) => {
              return (
                <Block
                  key={key}
                  subtree={SectionFiltrada.isBlock}
                  block={bloque}
                  sectionId={SectionFiltrada.id}
                  userHasSuscription={userHasSuscription ?? null}
                ></Block>
              )
            })}
          </>
        ) : null}

        {showOtherBlock &&
        section.blocks &&
        section.blocks.length > 0 &&
        SectionFiltrada === null ? (
          <>
            {section.blocks.map((bloque, key) => {
              return (
                <>
                  <Block
                    key={key}
                    subtree={section.isBlock}
                    block={bloque}
                    sectionId={section.id}
                    userHasSuscription={userHasSuscription ?? null}
                  ></Block>
                </>
              )
            })}
          </>
        ) : null}
      </main>
      <SignInModal
        show={showSignInForm}
        onClose={() => {
          setShowSignInForm(false)
        }}
      />
      <PaymentMethodModal
        show={showPaymentMethod}
        onClose={() => {
          setShowPaymentMethod(false)
        }}
        newPaymentMethod={value => {
          setShowNewPayment(value)
          setShowPaymentMethod(false)
        }}
        message={value => {
          setShowSuccessful(value)
          //setUserSuscription('premium');
          suscription("premium")
          setShowPaymentMethod(false)
        }}
        // deteleMessage ={(value)=>handleMessageDelete(value)}
        // deleteConfirmation ={deleteConfirmation}
        setIsApplePay={setIsApplePay}
      />
      <NewPaymentFormModal
        show={showNewPayment}
        onClose={() => {
          setShowNewPayment(false)
        }}
        back={() => {
          setShowPaymentMethod(true)
          setShowNewPayment(false)
        }}
        cvvHelp={() => setShowCvvHelp(true)}
        messageOk={value => {
          //setUserSuscription('premium');
          suscription("premium")
          setShowSuccessful(value)
          setShowNewPayment(false)
        }}
      />
      <SubscriptionMessageModal
        isApplePay={isApplePay}
        show={showSuccessful}
        onClose={() => {
          setShowSuccessful(false)
          setShowPerfilForm(true)
        }}
      />
      <CvvModal
        show={showCvvHelp}
        onClose={() => {
          setShowCvvHelp(false)
        }}
      />

      {!ispwa && userHasSuscription === "premium" ? (
        <PerfilForm
          show={showPerfilForm}
          ispwaComponent={ispwa}
          onClose={() => {
            setShowPerfilForm(false)
          }}
        />
      ) : null}
    </div>
  )
}

export default Section
